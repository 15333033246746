
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    mixins: [Base],
    methods: {
        deleteItem(): void {
            this.delete(`platforms/${this.$route.params.id}`)
                .then(() => {
                    this.$router.push({ name: 'platforms.list' });
                    this.$emit('reload');
                });
        },
    },
});
